<template>
  <div class="elv-base-cell-icon-title">
    <SvgIcon
      v-if="props.svgName"
      class="elv-base-cell-icon-title-svg"
      :class="{ 'elv-base-cell-icon-title-svg-rect': props.isRectIcon }"
      :name="svgName"
      :width="svgSize || 14"
      :height="svgSize || 14"
    />
    <img
      v-if="props.icon"
      class="elv-base-cell-icon-title-img"
      :class="{ 'elv-base-cell-icon-title-img-rect': props.isRectIcon }"
      :src="props.icon"
    />
    <div
      class="elv-base-cell-icon-title-title"
      :title="props?.titleDesc || props.title"
      :style="{
        fontWeight: props.titleWeight || 600,
        fontFamily: props.titleFontFamily || 'Plus Jakarta Sans',
        fontSize: props.titleSize || '13px'
      }"
    >
      {{ props.title }}
    </div>
    <div
      v-if="props.memo"
      class="elv-base-cell-icon-title-memo"
      :style="props.isMemoBold ? 'font-weight: 600;' : 'font-weight: 400;'"
    >
      {{ props.memo }}
    </div>
  </div>
</template>
<script setup lang="ts">
const props = defineProps<{
  icon?: string
  title: string
  titleDesc?: string
  titleFontFamily?: string
  titleWeight?: number
  titleSize?: string
  memo?: string | number
  isMemoBold?: boolean
  svgName?: string
  svgSize?: number
  isRectIcon?: boolean
}>()
</script>
<style lang="scss">
.elv-base-cell-icon-title {
  display: flex;
  align-items: center;
  padding: 12px 10px;

  &-svg {
    margin-right: 8px;
    display: block;
    border-radius: 50%;

    &-rect {
      border-radius: 0;
    }
  }

  &-img {
    margin-right: 8px;
    width: 18px;
    height: 18px;
    display: block;
    border-radius: 50%;

    &-rect {
      border-radius: 0;
    }
  }

  &-title {
    margin-right: 4px;
    color: #0e0f11;
    font-family: 'Plus Jakarta Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    white-space: nowrap;
  }

  &-memo {
    color: #838d95;
    font-family: 'Barlow';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    // text-transform: uppercase;
    white-space: nowrap;
  }
}
</style>
